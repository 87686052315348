/* Base style for the whole app */
body {
  background-color: #121212;
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: auto;
  padding: 10px;
}

/* Navigation */
.nav-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
}
link,
a,
link:visited,
a:visited {
  font-style: none;
  text-decoration: none;
  color: #d3d3d3;
}
ul {
  list-style: none;

  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0px;
  font-size: 2em;
}
p {
  text-align: justify;
}
h1 {
  font-size: 2em;
}
.desktop-nav-list {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  list-style: none;
  font-size: 2em;
}
.mobile-nav-div {
  display: none;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
}
/* Log Div style */
.LogDiv {
  background-color: #1e1e1e;
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 80%;
}

.LogDiv h3 {
  text-align: right;
}

/* When the user is logged in */
.LoggedInLogsDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Log Line style */
.LogLine {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Tables */
.desktop-header {
  display: block;
}
.mobile-header {
  display: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  color: #ddd;
  margin-top: 10px;
}

th,
td {
  border: 1px solid #444;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #333;
}

tr:nth-child(even) {
  background-color: #444;
}

tr:hover {
  background-color: #555;
}

/* Clickable header */
.clickable-header {
  cursor: pointer;
  color: #a9a9a9; /* Dark Gray */
  transition: color 0.3s ease;
}

.clickable-header:hover {
  color: #d3d3d3; /* Light Gray */
}

/* Text color for black items */
.black-text {
  color: white;
}

.black-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 30% 30% 30%;
}
.projects-page {
  width: 80%;
}
/* Styling for project items */
.project-item,
.black-item {
  color: white;
  background-color: #333;
  font-size: 0.5em;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-input,
.add-button {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
}

.project-input {
  color: black;
}

.add-button {
  padding: 10px 20px;
}

.addProjectsDiv {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  margin-bottom: 20px;
}

.black-input {
  border: 2px solid white;
  border-radius: 5px;
  padding: 5px 10px;
  flex: 4;
}

.black-button {
  background-color: #666;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  flex: 1;
}

.black-button:hover {
  background-color: #888;
}

.trash-button {
  background-color: #1a1a1a; /* Very Dark Gray */
  border: 1px solid #4a4a4a; /* Not-as-dark Gray */
  color: #ddd; /* Light Gray */
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.trash-button:hover {
  background-color: #333; /* Dark Gray */
  color: #fff; /* White */
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal */
.modal {
  background: white;
  border-radius: 8px;
  width: 300px;
  height: auto;
  padding: 20px;
  position: relative;
}

/* Modal Header */
.modal-header {
  font-size: 24px;
  margin-bottom: 15px;
}

/* Modal Close Button */
.close-button {
  position: absolute;
  top: 5px;
  right: 15px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 24px;
}

/* Modal Body */
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Modal Footer */
.modal-footer {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

/* Search Bar */
.searchBar {
  background-color: #1a1a1a; /* Very Dark Gray */
  border: 1px solid #4a4a4a; /* Not-as-dark Gray */
  color: #ddd; /* Light Gray */
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 80%;
}

.searchBar:focus {
  border-color: #007bff;
}

/* Stylish Button */
.stylish-button {
  background-color: #1a1a1a; /* Very Dark Gray */
  border: 1px solid #4a4a4a; /* Not-as-dark Gray */
  color: #ddd; /* Light Gray */
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.stylish-button:hover {
  background-color: #333; /* Dark Gray */
  color: #fff; /* White */
}

/* Collapse Button */
.collapse-button {
  background-color: #333; /* Dark Gray */
  border: 1px solid #555; /* Lighter Gray */
  color: #fff; /* White */
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.collapse-button:hover {
  background-color: #444; /* Slightly Lighter Gray */
}

/* Collapsible Content */
.collapsible-content {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 20000px;
}

.collapsible-content.collapsed {
  max-height: 0;
}

/* Chart Page */
.chart-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Chart Container */

.chart-container {
  background-color: #1e1e1e;
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 80%;
  cursor: pointer;
  height: fit-content;
}

.chart-title {
  text-align: right;
  cursor: pointer;
}
.collapse-button-chart {
  width: 40px;
  height: 40px;
  right: 5px;
  top: 5px;
  background-color: #1a1a1a; /* Very Dark Gray */
  border: 1px solid #4a4a4a; /* Not-as-dark Gray */
  color: #ddd; /* Light Gray */
}

.VictoryContainer {
  z-index: 1;
}
/* Collapsed Chart Div */
.collapsed-chart-div {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  display: flex;
  max-height: 20000px;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Chart Div */
.chart-div {
  width: 45%;
  height: fit-content;
}

/* Home  */
.home-list {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-around;
}
.home-div {
  width: 80%;
}

.slogan-p,
.mission-statement-p,
.call-to-action-p {
  font-size: 2em;
}
.home-h1 {
  font-size: 4em;
}
.home-h2 {
  font-size: 3em;
}
/* Footer */
footer {
  height: 100px;
  position: relative;
  width: 100%;
}
.zefmon-logo-footer {
  height: 90px;
  position: absolute;
  left: 10%;
  bottom: 10px;
}

/* login Form */

.loginMain {
  display: flex;
  width: 30%;
  border-radius: 25px;
  flex-direction: column;
  background-color: #1e1e1e;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  padding-bottom: 25px;
}
.loginForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
}
.submit-button {
  width: 50%;
  background-color: #1a1a1a; /* Very Dark Gray */
  border: 1px solid #4a4a4a; /* Not-as-dark Gray */
  color: #ddd; /* Light Gray */
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submit-button:hover {
  background-color: #333; /* Dark Gray */
  color: #fff; /* White */
}
