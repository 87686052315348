@media (max-width: 768px) {
  /* Mobile styles here */
  body {
    padding: 0;
  }

  ul {
    font-size: 1.5em;
  }

  .LogDiv,
  .project-item {
    width: 100%;
  }

  .collapse-button {
    margin-left: 0;
  }

  /* nav */
  .desktop-nav-div {
    display: none;
  }
  .mobile-nav-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 2;
  }

  .collapse-menu-div {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: black;
    z-index: 2;
  }
  .mobile-nav-list {
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    padding: 0px;

    align-items: flex-end;
  }
  .collapse-menu-btn {
    width: 40px;
    height: 40px;
    position: fixed;
    right: 5px;
    top: 5px;
    z-index: 3;
    background-color: #1a1a1a; /* Very Dark Gray */
    border: 1px solid #4a4a4a; /* Not-as-dark Gray */
    color: #ddd; /* Light Gray */
  }

  /* Tables */

  .LogDiv .collapsible-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .logstable {
    width: 100%;
  }
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
  .directory-header,
  .directory-row,
  .projectname-header,
  .projectname-row {
    display: none;
  }
  .filename-header,
  .filename-row {
    width: 80%;
  }
  .lastupdate-header,
  .lastupdate-row {
    width: 10%;
  }

  /* Projects */
  .black-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .projects-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .project-item,
  .addProjectsDiv {
    width: 80%;
  }
}
